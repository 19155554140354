exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyrights-js": () => import("./../../../src/pages/copyrights.js" /* webpackChunkName: "component---src-pages-copyrights-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-industry-js": () => import("./../../../src/pages/more/industry.js" /* webpackChunkName: "component---src-pages-more-industry-js" */),
  "component---src-pages-more-partners-js": () => import("./../../../src/pages/more/partners.js" /* webpackChunkName: "component---src-pages-more-partners-js" */),
  "component---src-pages-more-portfolio-js": () => import("./../../../src/pages/more/portfolio.js" /* webpackChunkName: "component---src-pages-more-portfolio-js" */),
  "component---src-pages-more-privacy-policy-js": () => import("./../../../src/pages/more/privacy-policy.js" /* webpackChunkName: "component---src-pages-more-privacy-policy-js" */),
  "component---src-pages-more-products-js": () => import("./../../../src/pages/more/products.js" /* webpackChunkName: "component---src-pages-more-products-js" */),
  "component---src-pages-more-recruitment-js": () => import("./../../../src/pages/more/recruitment.js" /* webpackChunkName: "component---src-pages-more-recruitment-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-polityka-prywatnosci-e-historie-js": () => import("./../../../src/pages/polityka-prywatnosci-e-historie.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-e-historie-js" */),
  "component---src-pages-zasady-i-warunki-e-historie-js": () => import("./../../../src/pages/zasady-i-warunki-e-historie.js" /* webpackChunkName: "component---src-pages-zasady-i-warunki-e-historie-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-blog-post-template-wp-js": () => import("./../../../src/templates/BlogPostTemplateWp.js" /* webpackChunkName: "component---src-templates-blog-post-template-wp-js" */)
}

